/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getAllEmpleadorEstado,
} from '@/utils/paths'

const pathGetAllEmpleadorEstado = getAllEmpleadorEstado

export function empleadorEstadoResource() {
  function getAllEmpleadorEstado() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAllEmpleadorEstado,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  return {
    getAllEmpleadorEstado,
  }
}
