<template>
  <div>
    <validation-observer ref="refDomicilioLaboralFisico">
      <b-row>
        <b-col md="6">
          <b-form-group
            v-if="!virtual"
            id="frmCalle"
            label="Calle"
          >
            <validation-provider
              name="calle"
              rules="required|max:250"
            >
              <div slot-scope="{ errors, failedRules }">
                <b-form-input
                  id="txtCalle"
                  v-model="domicilioLaboralDto.domicilioCalle"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                  maxlength="250"
                  :disabled="!isEditable"
                />
                <small
                  v-if="failedRules.required"
                  class="text-danger"
                >Calle obligatoria.</small>
                <small
                  v-if="failedRules.max"
                  class="text-danger"
                >Máximo 250 caracteres.</small>
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            v-if="!virtual"
            id="frmNumero"
            label="Número"
          >
            <validation-provider
              name="numero"
              rules="required|max:50"
            >
              <div slot-scope="{ errors, failedRules }">
                <b-form-input
                  id="txtDomicilioNumero"
                  v-model="domicilioLaboralDto.domicilioNumero"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                  type="number"
                  maxlength="50"
                  oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  :disabled="!isEditable"
                  @input="restrictToNumbers"
                />
                <small
                  v-if="failedRules.required"
                  class="text-danger"
                >Número obligatorio.</small>
                <small
                  v-if="failedRules.max"
                  class="text-danger"
                >Máximo 50 caracteres.</small>
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            v-if="!virtual"
            id="frmOficinaDepto"
            label="Oficina/Depto"
          >
            <validation-provider name="oficinaDepto">
              <div slot-scope="{ errors }">
                <b-form-input
                  id="txtOficinaDepto"
                  v-model="domicilioLaboralDto.domicilioOficinaDepto"
                  :state="errors.length > 0 ? false:null"
                  maxlength="250"
                  autocomplete="off"
                  :disabled="!isEditable"
                />
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            v-if="!virtual"
            id="frmCodigoPostal"
            label="Código Postal"
          >
            <validation-provider
              name="codigoPostal"
              rules="required|max:50"
            >
              <div slot-scope="{ errors, failedRules }">
                <b-form-input
                  id="txtCodigoPostal"
                  v-model="domicilioLaboralDto.codigoPostal"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                  type="number"
                  :disabled="isBusyBuscandoLocalidad || isBusyBuscandoProvincias || !isEditable"
                  @input="restrictToNumbers"
                />
                <small
                  v-if="failedRules.required"
                  class="text-danger"
                >Código Postal obligatorio.</small>
                <small
                  v-if="failedRules.max"
                  class="text-danger"
                >Máximo 50 caracteres.</small>
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            v-if="!virtual"
            id="frmLocalidad"
            label="Localidad"
          >
            <validation-provider
              name="localidad"
              rules="required"
            >
              <div slot-scope="{ errors, failedRules }">

                <small v-if="isBusyBuscandoLocalidad">
                  <span
                    v-if="isBusyBuscandoLocalidad"
                    class="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  /> Buscando localidades
                </small>
                <b-form-select
                  v-if="!isBusyBuscandoLocalidad"
                  id="selectLocalidad"
                  v-model="domicilioLaboralDto.localidad.id"
                  v-b-tooltip.hover="'Seleccione la localidad'"
                  :state="errors.length > 0 ? false:null"
                  :options="optionsLocalidad"
                  value-field="id"
                  text-field="nombre"
                  :aria-busy="isBusyBuscandoLocalidad ? 'true' : 'false'"
                  :disabled="isBusyBuscandoProvincias || !isEditable"
                  rounded
                >
                  <template #first>
                    <b-form-select-option
                      v-if="!isBusyBuscandoLocalidad"
                      :value="null"
                      disabled
                    >
                      Seleccione
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <small
                  v-if="failedRules.required && !isBusyBuscandoLocalidad"
                  class="text-danger"
                >
                  Localidad obligatoria.
                </small>
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            v-if="!virtual"
            id="frmProvincia"
            label="Provincia"
          >
            <validation-provider
              name="provincia"
              rules="required"
            >
              <div slot-scope="{ errors, failedRules }">
                <b-form-input
                  v-if="!isBusyBuscandoProvincias"
                  id="txtProvincia"
                  v-model="domicilioLaboralDto.provincia.nombre"
                  :state="errors.length > 0 ? false : null"
                  plaintext
                />
                <small v-if="isBusyBuscandoProvincias">

                  <span
                    class="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  /> Buscando provincia
                </small>
                <small
                  v-if="failedRules.required && !isBusyBuscandoProvincias"
                  class="text-danger"
                >
                  Provincia obligatoria.
                </small>
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            id="frmDelegacion"
            label="Delegación"
          >
            <validation-provider
              name="delegacion"
              rules="required"
            >
              <div slot-scope="{ errors, failedRules }">
                <b-form-select
                  id="selectDelegacion"
                  v-model="domicilioLaboralDto.delegacion.id"
                  v-b-tooltip.hover="'Seleccione la delegación'"
                  :options="optionsDelegacion"
                  :state="errors.length > 0 ? false:null"
                  value-field="id"
                  text-field="nombre"
                  :disabled="isBusyBuscando || !isEditable"
                  rounded
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >
                      Seleccione
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <small
                  v-if="failedRules.required"
                  class="text-danger"
                >
                  Delegación obligatoria.
                </small>
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            id="frmEmpleadorEstado"
            label="Estado"
          >
            <validation-provider
              name="empleadorEstado"
              rules="required"
            >
              <div slot-scope="{ errors, failedRules }">
                <b-form-select
                  id="selectEmpleadorEstadi"
                  v-model="domicilioLaboralDto.domicilioLaboralEstado.id"
                  v-b-tooltip.hover="'Seleccione el estado'"
                  :state="errors.length > 0 ? false:null"
                  :options="optionsEstado"
                  value-field="id"
                  text-field="nombre"
                  :aria-busy="isBusyBuscandoEstados ? 'true' : 'false'"
                  :disabled="openMode !== 'edit'"
                  rounded
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >
                      Seleccione
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <small
                  v-if="failedRules.required"
                  class="text-danger"
                >
                  Estado obligatorio.
                </small>
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>
<script>
import { localidadResource } from '@/services/localidadResource'
import { domicilioLaboralEstadoResource } from '@/services/domicilioLaboralEstadoResource'
import { delegacionResource } from '@/services/delegacionResource'

export default {
  props: {
    empleadorDto: {
      type: Object,
      required: false,
      default: null,
    },
    showDomicilioLaboralModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    domicilioLaboralModal: {
      type: Object,
      required: true,
    },
    openMode: {
      type: String,
      required: true,
    },
    selectedDomicilioType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: '',
      isWorking: false,
      domicilioDtoTemporal: [],
      domicilioLaboralDto: {
        id: null,
        domicilioCalle: null,
        domicilioNumero: null,
        domicilioOficinaDepto: null,
        codigoPostal: null,
        provincia: {
          id: null,
          nombre: null,
        },
        localidad: {
          id: null,
          nombre: null,
        },
        delegacion: {
          id: null,
          nombre: null,
        },
        domicilioLaboralEstado: {
          id: 1,
          nombre: null,
        },
        domicilioLaboralTipo: {
          id: 2,
          tipo: null,
        },
        empleador: {
          id: null,
          nombreFantasia: null,
        },
      },
      optionsEstado: [],
      optionsDelegacion: [],
      optionsLocalidad: [],
      isBusyBuscandoProvincias: false,
      virtual: false,
      isBusyBuscandoLocalidad: false,
      isBusyBuscando: false,
      isBusyBuscandoDelegacion: false,
      isBusyBuscandoEstados: false,
    }
  },
  computed: {
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.editarEnableDisable)
    },
  },
  watch: {
    selectedDomicilioType(newType) {
      if (newType === 'VIRTUAL') {
        this.virtual = true
      } else if (newType === 'FISICO') {
        this.virtual = false
      }
    },
    'domicilioLaboralDto.codigoPostal': {
      handler(codigoPostal) {
        this.optionsLocalidad = []
        if (codigoPostal && codigoPostal.length === 4) {
          this.getLocalidadLista(codigoPostal)
        } else {
          this.domicilioLaboralDto.localidad.id = null
        }
      },
    },
    'domicilioLaboralDto.localidad.id': {
      handler(localidadId) {
        if (localidadId) {
          this.getProvinciaPorLocalidad(localidadId)
        }
      },
    },
  },
  created() {
    if (this.openMode === 'edit' || this.openMode === 'view') {
      this.cambiarTipoDomicilio()
    } else if (this.openMode === 'new') {
      if (this.selectedDomicilioType === 'FISICO') {
        this.virtual = false
      } else if (this.selectedDomicilioType === 'VIRTUAL') {
        this.virtual = true
      }
    }
    this.getDomicilioLaboralEstadoLista()
    this.getDelegacionLista()
  },
  methods: {
    restrictToNumbers(event) {
      if (event && event.target) {
        const input = event.target
        if (input.value) {
          input.value = input.value.replace(/\D/g, '')
        }
      }
    },
    generarRandomId() {
      return `generated${Math.random().toString(36).substring(7)}`
    },
    emiteNew() {
      this.domicilioLaboralDto.id = this.generarRandomId()
      this.findByNombres()
      const dto = { ...this.domicilioLaboralDto }
      if (this.selectedDomicilioType === 'VIRTUAL') {
        dto.domicilioLaboralTipo = {
          id: 1,
          tipo: 'VIRTUAL',
        }
        dto.domicilioCalle = 'VIRTUAL'
        dto.domicilioNumero = null
        dto.codigoPostal = null
        dto.provincia = {
          id: null,
          nombre: null,
        }
        dto.localidad = {
          id: null,
          nombre: null,
        }
      } else if (this.selectedDomicilioType === 'FISICO') {
        dto.domicilioLaboralTipo = {
          id: 2,
          tipo: 'FISICO',
        }
      }
      this.$emit('handle-dto-submitted', dto)
    },
    emiteUpdate() {
      this.findByNombres()
      if (this.selectedDomicilioType === 'VIRTUAL') {
        this.domicilioLaboralDto.domicilioLaboralTipo = {
          id: 1,
          tipo: 'VIRTUAL',
        }
        this.domicilioLaboralDto.domicilioCalle = 'VIRTUAL'
        this.domicilioLaboralDto.domicilioNumero = null
        this.domicilioLaboralDto.domicilioOficinaDepto = null
        this.domicilioLaboralDto.codigoPostal = null
        this.domicilioLaboralDto.localidad.id = null
        this.domicilioLaboralDto.localidad.nombre = null
        this.domicilioLaboralDto.provincia.id = null
        this.domicilioLaboralDto.provincia.nombre = null
      } else if (this.selectedDomicilioType === 'FISICO') {
        this.domicilioLaboralDto.domicilioLaboralTipo = {
          id: 2,
          tipo: 'FISICO',
        }
      }
      this.$emit('update-dto-submitted', this.domicilioLaboralDto)
    },
    findByNombres() {
      this.optionsDelegacion.forEach(element => {
        if (element.id === this.domicilioLaboralDto.delegacion.id) {
          this.domicilioLaboralDto.delegacion.nombre = element.nombre
        }
      })
      this.optionsLocalidad.forEach(element => {
        if (element.id === this.domicilioLaboralDto.localidad.id) {
          this.domicilioLaboralDto.localidad.nombre = element.nombre
        }
      })
      this.optionsEstado.forEach(element => {
        if (element.id === this.domicilioLaboralDto.domicilioLaboralEstado.id) {
          this.domicilioLaboralDto.domicilioLaboralEstado.nombre = element.nombre
        }
      })
    },
    validationDomicilioLaboralModal() {
      this.$refs.refDomicilioLaboralFisico.validate().then(success => {
        if (success) {
          if (this.openMode === 'edit') {
            this.emiteUpdate()
          }
          if (this.openMode === 'new') {
            this.emiteNew()
          }
        }
      })
    },
    mostrar() {
      this.domicilioLaboralDto = this.domicilioLaboralModal
      // this.selectedDomicilioType = this.domicilioLaboralDto.domicilioLaboralTipo.id === 1 ? 'fisico' : 'virtual'
    },
    cambiarTipoDomicilio() {
      this.domicilioLaboralDto = this.domicilioLaboralModal
      if (this.domicilioLaboralDto.domicilioLaboralTipo.tipo === 'FISICO') {
        this.virtual = false
        this.selectedDomicilioType = 'FISICO'
      } else if (this.domicilioLaboralDto.domicilioLaboralTipo.tipo === 'VIRTUAL') {
        this.virtual = true
        this.selectedDomicilioType = 'VIRTUAL'
      }
    },
    getDomicilioLaboralEstadoLista() {
      this.isBusyBuscandoEstados = true
      domicilioLaboralEstadoResource().getAllEstado().then(result => {
        this.optionsEstado = this.optionsEstado.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEstados = false
        })
    },
    getDelegacionLista() {
      this.isBusyBuscandoDelegacion = true
      delegacionResource().findAllByIsActivoDelegacion().then(result => {
        this.optionsDelegacion = this.optionsDelegacion.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoDelegacion = false
        })
    },
    getLocalidadLista(codigo) {
      this.isBusyBuscandoLocalidad = true
      localidadResource().getByCodigoLocalidad(codigo).then(result => {
        // this.optionsLocalidad = this.optionsLocalidad.concat(result)
        this.optionsLocalidad = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoLocalidad = false
        })
    },
    getProvinciaPorLocalidad(localidadId) {
      this.isBusyBuscandoProvincias = true
      localidadResource().getLocalidadId(localidadId)
        .then(result => {
          this.domicilioLaboralDto.provincia.id = result.id
          this.domicilioLaboralDto.provincia.nombre = result.nombre
        }).catch(error => {
          console.error('error:', error)
        })
        .finally(() => {
          this.isBusyBuscandoProvincias = false
        })
    },
  },
}
</script>
