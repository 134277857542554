<template>
  <div>
    <!-- modal -->
    <b-modal
      id="mdlNuevoDocumentoDigital"
      v-model="showDocumentoCategoriaModal"
      :title="'Documentaciones disponibles'"
      ok-title="Aceptar"
      cancel-title="Cancelar"
      ok-variant="primary"
      size="sm"
      no-close-on-backdrop
      no-close-on-esc
      centered
      @ok="okModal"
      @close="closeModalDocumentacion"
      @cancel="closeModalDocumentacion"
    >
      <validation-observer ref="modalAddDocumentacion">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                id="frmTipoDocCat"
                label="Documentación"
              >
                <validation-provider
                  name="documentacion"
                  rules="required"
                >
                  <div slot-scope="{ errors, failedRules }">

                    <b-form-select
                      id="selectTipoDoc"
                      v-model="empleadorDocumentoCatetoriaId"
                      v-b-tooltip.hover="'Seleccione el tipo de documento'"
                      :state="errors.length > 0 ? false:null"
                      :options="tableDataDocumentoCategoria"
                      value-field="id"
                      text-field="nombre"
                      rounded
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          Seleccione
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >
                      Debe seleccionar tipo de documentación
                    </small>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="d-flex justify-content-between">
          <b-button
            size="sm"
            squared
            variant="danger"
            @click="closeModalDocumentacion"
          >
            Cerrar
          </b-button>

          <b-button
            size="sm"
            squared
            variant="primary"
            @click="okModal"
          >
            Aceptar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  required,
} from '@validations'

export default {
  name: 'DocumentacionModal',
  components: {
  },
  props: {
    showDocumentoCategoriaModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    tableDataDocumentoCategoria: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      empleadorDocumentoCatetoriaId: null,
      nameModulo: 'ABM_EMPLEADORES',
      required,
      tableDataCategoria: [],
    }
  },
  computed: {
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        user: 'currentUser',
      },
    ),
  },
  created() {
    this.tableDataCategoria = this.tableDataDocumentoCategoria
  },
  methods: {
    closeModalDocumentacion(bvModalEvt) {
      this.$emit('closeModalDocumentacion')
      bvModalEvt.preventDefault()
    },
    okModal(bvModalEvt) {
      this.validationFormModalUploadFile()
      bvModalEvt.preventDefault()
    },
    validationFormModalUploadFile() {
      this.$refs.modalAddDocumentacion.validate().then(success => {
        if (success) {
          this.$emit('agregarDocumentacion', this.empleadorDocumentoCatetoriaId)
        }
      })
    },
  },
}
</script>
