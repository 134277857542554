<template>
  <div>
    <h4 v-if="openMode == 'new' || openMode == 'incompleteLoad'">
      {{ title }}
    </h4>
    <b-overlay
      :show="isWorking || isSearching"
      :rounded="overlayConfig.rounded"
      :variant="overlayConfig.variant"
      :opacity="overlayConfig.opacity"
      :blur="overlayConfig.blur"
    >
      <template #overlay>
        <div class="mt-2">
          <div style="text-align: center">
            <span class="spinner-border " />
          </div>
          <div style="text-align: center">
            <small>{{ overlayConfig.message }}</small>
          </div>
        </div>
      </template>

      <validation-observer ref="refDatosGenerales">
        <b-row>
          <b-col md="10">
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="frmCuitDatosGenerales"
                  label="C.U.I.T."
                >
                  <validation-provider
                    name="cuit"
                    rules="required|min:11"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="block"
                        v-maska="bindedObject"
                        :value="datosGeneralesDto.cuit"
                        :state="errors.length > 0 ? false:null"
                        data-maska="##-########-#"
                        autocomplete="off"
                        :disabled="!isEditable"
                        placeholder="xx-xxxxxxxx-x"
                        @maska="onMaska"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >C.U.I.T. obligatorio.
                      </small>
                      <small
                        v-if="failedRules.min"
                        class="text-danger"
                      >C.U.I.T. incompleto.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="frmRazonSocialEmpleador"
                  label="Razón social"
                >
                  <validation-provider
                    name="razonSocialEmpleador"
                    rules="required|max:250|min:2"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtRazonSocialEmpleador"
                        v-model="datosGeneralesDto.razonSocial"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                        aria-describedby="input-live-help input-live-feedback"
                        maxlength="250"
                        :disabled="!isEditable"
                      />
                      <b-form-invalid-feedback id="input-live-feedback">
                        Ingrese al menos dos letras
                      </b-form-invalid-feedback>
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Razón social obligatoria.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 250 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="frmNombreFantasiaEmpleador"
                  label="Nombre fantasía"
                >
                  <validation-provider
                    name="nombreFantasiaEmpleador"
                    rules="required|max:250"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtNombreFantasiaEmpleador"
                        v-model="datosGeneralesDto.nombreFantasia"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                        maxlength="250"
                        :disabled="!isEditable"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Nombre obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 250 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="frmCategoria"
                  label="Categoría"
                >
                  <validation-provider
                    name="categoria"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-select
                        id="selectCategoria"
                        v-model="datosGeneralesDto.empleadorCategoria.id"
                        v-b-tooltip.hover="'Seleccione la categoría'"
                        :state="errors.length > 0 ? false:null"
                        :options="optionsCategoria"
                        value-field="id"
                        text-field="nombre"
                        rounded
                        :disabled="!isEditable"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >
                        Categoría obligatoria.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="empleadorDto.id != null"
                md="4"
              >
                <b-form-group
                  id="frmFechaCarga"
                  label="Fecha de carga"
                >
                  <cleave
                    id="date"
                    :value="datosGeneralesDto.fechaCarga"
                    class="form-control"
                    :raw="true"
                    :options="options.date"
                    autocomplete="off"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="frmEstado"
                  label="Estado"
                >
                  <b-form-select
                    id="selectEstado"
                    v-model="datosGeneralesDto.empleadorEstado.id"
                    v-b-tooltip.hover="'Seleccione el estado'"
                    :options="optionsEstado"
                    value-field="id"
                    text-field="nombre"
                    rounded
                    :disabled="!condition"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="2">
            <b-row class="text-center">
              <b-col md="12">
                <b-avatar
                  ref="previewEl"
                  v-b-tooltip.hover="isEditable ? getMensaje : ''"
                  button
                  variant="outline-secondary"
                  :src="datosGeneralesDto.logo"
                  size="150px"
                  :square="true"
                  @click="openLogoInput"
                >
                  <h4 v-if="!datosGeneralesDto.logo">
                    Logo
                  </h4>
                </b-avatar>
              </b-col>
            </b-row>
            <b-row class="text-center">
              <b-col md="12">
                <b-button
                  v-if="datosGeneralesDto.logo && isEditable"
                  v-b-tooltip.hover="'Quitar logo'"
                  variant="danger"
                  class="w-100 m-0"
                  squared
                  size="sm"
                  :disabled="!isEditable"
                  @click="confirmDelete"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </b-col>
            </b-row>

            <input
              ref="logoInput"
              :key="logoInputKey"
              type="file"
              accept="image/*"
              style="display: none"
              @change="handleLogoChange"
            >
          </b-col>
          <b-col md="1">
            <div
              style="float: right;"
              class="btn-group"
              role="group"
              aria-label="Basic example"
            >
              <b-button
                v-if="permisosUpd.includes(nameModulo) && showBtnEdit"
                id="btnEditEmpleador"
                v-b-tooltip.hover
                variant="light"
                squared
                title="Editar Empleador"
                data-toggle="button"
                aria-pressed="true"
                autocomplete="on"
                size="sm"
                :disabled="empleadorDto.id !== undefined"
                @click="updateDatosGenerales()"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
    <hr>
    <b-row>
      <b-col md="4">
        <b-button
          id="btnVolverEmpleadores"
          v-b-tooltip.hover
          title="Volver a listado empleadores"
          variant="light"
          squared
          @click="()=>backEmpleadores()"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
      </b-col>
      <b-col
        md="2"
        class="text-right px-0"
      >
        <b-button
          v-if="isWizard"
          id="btnPasoAnterior"
          v-b-tooltip.hover
          title="Anterior"
          variant="outline-primary"
          squared
          style="primary"
          :disabled="isWorking || isFirstStep"
          @click="anteriorWizard()"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
      </b-col>
      <b-col
        md="2"
        class="text-left  px-0"
      >
        <b-button
          v-if="isWizard"
          id="btnPasoPosterior"
          v-b-tooltip.hover
          squared
          variant="outline-primary"
          title="Siguiente"
          type="submit"
          :disabled="(isWorking || isSearching) || !isEditable"
          @click.prevent="posteriorWizard()"
        >
          <span />
          <feather-icon
            v-if="!isWorking"
            icon="ArrowRightIcon"
          />
          <span
            v-if="isWorking"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
      <b-col
        v-if="!isWizard"
        md="4"
        class="text-right"
      >
        <b-button
          v-if="permisosUpd.includes(nameModulo)"
          id="btnGuardarDatosGenerales"
          v-b-tooltip.hover
          class="text-end"
          variant="primary"
          squared
          title="Guardar datos generales"
          type="submit"
          :disabled="!isEditable || (JSON.stringify(datosGeneralesDto) === JSON.stringify(datosGeneralesDtoOriginal))"
          @click.prevent="validarFormulario"
        >
          <font-awesome-icon
            v-if="!isWorking"
            icon="fa-solid fa-floppy-disk"
          />
          <span
            v-if="isWorking"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Cleave from 'vue-cleave-component'
import { empleadorEstadoResource } from '@/services/empleadorEstadoResource'
import { empleadorCategoriaResource } from '@/services/empleadorCategoriaResource'
import { empleadoresResource } from '@/services/empleadoresResource'

export default {
  components: {
    Cleave,

  },
  props: {
    empleadorDto: {
      type: Object,
      required: false,
      default: null,
    },
    datosGenerales: {
      type: Object,
      required: false,
      default: null,
    },
    isWizard: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFirstStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    openMode: {
      type: String,
      required: true,
    },
    editarEnableDisable: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    isLastStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    idEmpleador: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      title: 'Nuevo Empleador',
      nameModulo: 'ABM_EMPLEADORES',
      logoInputKey: 0,
      showBtnEdit: false,
      isWorking: false,
      isSearching: false,
      maxLogoSize: 2500000,
      datosGeneralesDto: {
        cuit: null,
        razonSocial: null,
        nombreFantasia: null,
        fechaCarga: null,
        empleadorCategoria: {
          id: null,
          nombre: null,
        },
        empleadorEstado: {
          id: 1,
          nombre: null,
        },
        logo: null,
        etapaCompletadaDatosGenerales: false,
      },
      logoDto: {
        empleadorDto: {
          id: null,
          nombreFantasia: null,
        },
        logo: null,
        pathfile: null,
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
      // estado solo tiene que estar en estado activo al dar de alta
      optionsCategoria: [
        { id: null, nombre: 'Seleccione' },
      ],
      cuitRegistrado: false,
      optionsEstado: [],
      datosGeneralesDtoOriginal: {},
      isBusyBuscando: false,
      editableFirstValidation: false,
      isBusyBuscandoCategorias: false,
      isBusyBuscandoEstados: false,
      hasChanges: false,
      options: {
        block: {
          blocks: [2, 8, 1],
          uppercase: true,
        },
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        customDelimiter: {
          delimiters: ['-', '-'],
          blocks: [2, 8, 1],
          uppercase: true,
          numericOnly: true,
        },
      },
      bindedObject: {
        masked: '',
        unmasked: '',
        completed: false,
      },
    }
  },
  computed: {
    getMensaje() {
      return this.datosGeneralesDto.logo ? 'Cambiar logo' : 'Agregar un logo'
    },
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.openMode === 'incompleteLoad' || this.editarEnableDisable)
    },
    condition() {
      return ((this.openMode === 'edit' && this.isEditable) || (this.openMode === 'view' && this.isEditable))
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        user: 'currentUser',
      },
    ),
  },
  watch: {
    'datosGeneralesDto.cuit': {
      handler(newCuit, oldCuit) {
        if (oldCuit !== null) {
          if (newCuit && newCuit.length === 11) {
            if (this.datosGeneralesDtoOriginal.cuit !== null && this.datosGeneralesDtoOriginal.cuit !== newCuit) {
              this.verificarCuit(newCuit)
            }
          }
        }
      },
    },
  },
  created() {
    if (this.datosGenerales.cuit !== null) {
      this.datosGeneralesDto = this.deepCopy(this.datosGenerales)
      this.datosGeneralesDtoOriginal = this.deepCopy(this.datosGenerales)
    }
    if (this.openMode === 'edit' && this.isEditable) {
      this.editableFirstValidation = true
    }
    this.getEmpleadorEstadoLista()
    this.getEmpleadorCategoriaLista()
  },
  methods: {
    onMaska(val) {
      this.datosGeneralesDto.cuit = val.detail.unmasked
    },
    backEmpleadores() {
      this.$router.push('/empleadores')
    },
    anteriorWizard() {
      this.$emit('previous-step', this.empleadorDto)
    },
    posteriorWizard() {
      this.validarFormulario()
    },
    validarFormulario() {
      return this.$refs.refDatosGenerales.validate().then(success => {
        if (success) {
          if (this.empleadorDto.id === null) {
            this.saveDatosGenerales()
          } else if (JSON.stringify(this.datosGeneralesDto) !== JSON.stringify(this.datosGeneralesDtoOriginal)) {
            this.updateDatosGenerales()
          } else if (this.isWizard) {
            this.$emit('next-step', this.datosGeneralesDto, this.empleadorDto.id)
          }
        }
      })
    },
    deepCopy(obj) {
      if (obj === null || typeof obj !== 'object') {
        return obj
      }
      if (Array.isArray(obj)) {
        const copy = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < obj.length; i++) {
          copy[i] = this.deepCopy(obj[i])
        }
        return copy
      }
      if (typeof obj === 'object') {
        const copy = {}
        // eslint-disable-next-line no-restricted-syntax
        for (const key in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(key)) {
            copy[key] = this.deepCopy(obj[key])
          }
        }
        return copy
      }
      // Return obj if it's neither an array nor an object
      return obj
    },
    areObjectsEqual(objA, objB) {
      const keysA = Object.keys(objA)
      const keysB = Object.keys(objB)

      if (keysA.length !== keysB.length) {
        return false
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keysA) {
        if (objA[key] !== objB[key]) {
          return false
        }
      }

      return true
    },
    verificarCuit(cuit) {
      this.isSearching = true
      this.overlayConfig.message = 'Verificando C.U.I.T'
      empleadoresResource().findByCuitEmpleador(cuit).then(resultEmpleadorDto => {
        if (resultEmpleadorDto != null && Object.keys(resultEmpleadorDto).length > 0) {
          this.cuitRegistrado = true
          this.customizeSweetAlert2()
          if (this.openMode === 'new' || this.openMode === 'edit' || this.isEditable) {
            if (resultEmpleadorDto.cargaCompletada === true) {
              const htmlText = `<p style="color:${this.swalConfig.textColor}">¿Desea visualizar el empleador?</p>`
              this.$swal({
                title: 'El CUIT ya esta dado de alta',
                // text: '¿Desea visualizar el empleador?',
                html: htmlText,
                icon: 'warning',
                background: this.swalConfig.background,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                customClass: {
                  confirmButton: 'btn btn-primary square',
                  cancelButton: 'btn btn-danger square',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.value) {
                  this.$router.push(`/empleador/view/${resultEmpleadorDto.id}`)
                } else {
                  this.$router.push('/empleadores/')
                }
              })
            } else {
              const htmlText = `<p style="color:${this.swalConfig.textColor}">Faltan completar datos, ¿desea hacerlo ahora?</p>`
              this.$swal({
                title: 'El CUIT está en proceso de alta',
                // text: '¿faltan completar campos, desea hacerlo ahora?',
                html: htmlText,
                icon: 'warning',
                background: this.swalConfig.background,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                customClass: {
                  confirmButton: 'btn btn-primary square',
                  cancelButton: 'btn btn-danger square',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.value) {
                  this.$router.push(`/empleador/incompleteLoad/${resultEmpleadorDto.id}`)
                } else {
                  this.$router.push('/empleadores/')
                }
              })
            }
          }
        } else {
          this.cuitRegistrado = false
        }
      }).catch(error => {
        console.error('Error al verificar CUIT:', error)
      })
        .finally(() => {
          this.overlayConfig.message = ''
          this.isSearching = false
        })
    },
    saveDatosGenerales() {
      this.isWorking = true
      this.overlayConfig.message = 'Aguarde ...'
      empleadoresResource().saveEmpleador(this.datosGeneralesDto)
        .then(resultSaveDatosGenerales => {
          this.datosGeneralesDto.etapaCompletadaDatosGenerales = true
          this.datosGeneralesDto.fechaCarga = resultSaveDatosGenerales.fechaCarga
          this.$emit('guardarDatosGenerales', resultSaveDatosGenerales)
          if (this.isWizard) {
            this.$emit('next-step', this.datosGeneralesDto, resultSaveDatosGenerales.id)
          }
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    updateDatosGenerales() {
      this.isWorking = true
      empleadoresResource().updateDatosGenerales(this.empleadorDto.id, this.datosGeneralesDto)
        .then(resultUpdateDatosGenerales => {
          this.datosGeneralesDto.etapaCompletadaDatosGenerales = true
          this.datosGeneralesDto.fechaCarga = resultUpdateDatosGenerales.fechaCarga
          this.$emit('guardarDatosGenerales', resultUpdateDatosGenerales)
          if (this.isWizard) {
            this.$emit('next-step', this.datosGeneralesDto, resultUpdateDatosGenerales.id)
          }
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isWorking = false
        })
    },
    getEmpleadorEstadoLista() {
      this.isBusyBuscandoEstados = true
      empleadorEstadoResource().getAllEmpleadorEstado().then(result => {
        this.optionsEstado = this.optionsEstado.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEstados = false
        })
    },
    getEmpleadorCategoriaLista() {
      this.isBusyBuscandoEstados = true
      empleadorCategoriaResource().findAllByIsActivoEmpleadorCategoria().then(result => {
        this.optionsCategoria = this.optionsCategoria.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoCategorias = false
        })
    },
    openLogoInput() {
      // Incrementa el valor de logoInputKey para forzar la recreación del input de tipo "file"
      if (this.isEditable) {
        this.logoInputKey += 1
        this.$refs.logoInput.click()
      }
    },

    handleLogoChange(event) {
      const file = event.target.files[0]
      if (file) {
        if (file.size > this.maxLogoSize) {
          this.customizeSweetAlert2()
          const htmlText = `<p style="color:${this.swalConfig.textColor}">El tamaño del archivo debe ser menor a 2,5 MB.</p>`
          this.$swal({
            title: 'Tamaño de logo excede el límite',
            html: htmlText,
            icon: 'warning',
            background: this.swalConfig.background,
            confirmButtonText: 'Aceptar',
            customClass: {
              confirmButton: 'btn btn-primary square',
            },
            buttonsStyling: false,
          })
          return
        }
        // Para mostrar una vista previa del logo antes de cargarlo
        const reader = new FileReader()
        reader.onload = e => {
          this.datosGeneralesDto.logo = e.target.result
        }
        reader.readAsDataURL(file)
      }
    },
    confirmDelete() {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">¿Desea Eliminar el Logo?</p>`
      this.$swal({
        title: 'Eliminar Logo',
        // text: '¿Desea Eliminar el Logo?',
        html: htmlText,
        icon: 'warning',
        background: this.swalConfig.background,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.datosGeneralesDto.logo = null
        }
      })
    },
  },
}
</script>
