/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getAllDomicilioLaboralestado,
} from '@/utils/paths'

const pathGetAllDomicilioLaboralestado = getAllDomicilioLaboralestado

export function domicilioLaboralEstadoResource() {
  function getAllEstado() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAllDomicilioLaboralestado,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  return {
    getAllEstado,
  }
}
