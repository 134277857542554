/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getAllEmpleadorCategoria,
  getByIsActivoEmpleadorCategoria,
  postEmpleadorCategoria,
  putEmpleadorCategoria,
} from '@/utils/paths'

const pathGetAllEmpleadorCategoria = getAllEmpleadorCategoria
const pathGetByIsActivoEmpleadorCategoria = getByIsActivoEmpleadorCategoria
const pathPostEmpleadorCategoria = postEmpleadorCategoria
const pathPutEmpleadorCategoria = putEmpleadorCategoria
export function empleadorCategoriaResource() {
  function getAllEmpleadorCategoria() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAllEmpleadorCategoria,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoEmpleadorCategoria() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoEmpleadorCategoria)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveEmpleadorCategoria(empleadorCategoriaDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostEmpleadorCategoria, empleadorCategoriaDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateEmpleadorCategoria(id, empleadorCategoriaDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutEmpleadorCategoria + id, empleadorCategoriaDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    getAllEmpleadorCategoria,
    findAllByIsActivoEmpleadorCategoria,
    saveEmpleadorCategoria,
    updateEmpleadorCategoria,
  }
}
