<template>
  <div>
    <h4 v-if="openMode == 'new'|| openMode == 'incompleteLoad'">
      {{ title }}
    </h4>
    <b-overlay
      :show="isWorking"
      :rounded="overlayConfig.rounded"
      :variant="overlayConfig.variant"
      :opacity="overlayConfig.opacity"
      :blur="overlayConfig.blur"
    >
      <template #overlay>
        <div v-if="isWorking">
          <div style="text-align: center">
            <span class="spinner-border " />
          </div>
          <div style="text-align: center">
            <small>{{ overlayConfig.message }}</small>
          </div>
        </div>
      </template>
      <validation-observer ref="refDomicilioLaboral">
        <b-row>
          <b-col
            md="12"
            class="text-right"
          >
            <b-button
              v-if="permisosIns.includes(nameModulo)"
              id="btnAddDomicilioLaboral"
              v-b-tooltip.hover
              squared
              style="float: right;"
              size="sm"
              title="Nuevo Domicilio Laboral"
              variant="success"
              :disabled="isBusyBuscando || !isEditable"
              @click="openAddDomicilioModal"
              @hidden="resetFormFieldsDomicilioFisico && resetFormFieldsDomicilioVirtual"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-table
              id="tblDomicilioLaboral"
              ref="refTblDomicilioLaboral"
              :items="tableDataDomicilioLaboral"
              :busy="isBusyBuscando"
              :fields="fieldsDomicilioLaboral"
              outlined
              show-empty
              small
              hover
              sticky-header="70vh"
              :no-border-collapse="true"
              empty-text="No existen domicilios laborales cargados"
              @row-dblclicked="dobleClik"
            >
              <template v-slot:table-busy>
                <div class="text-center">
                  <b-spinner />
                </div>
              </template>
              <template #cell(estado)="row">
                <small>
                  {{ row.item.estado }}
                </small>
              </template>
              <template #cell(domicilioCalle)="row">
                <div :class="{ 'truncate-cell': openMode === 'new' }">
                  {{ row.item.domicilioCalle || '' }} {{ row.item.domicilioNumero || '' }}
                </div>
              </template>
              <template #cell(actions)="row">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <b-button
                    v-if="permisosUpd.includes(nameModulo)"
                    v-b-tooltip.hover.righttop="'Editar domicilio laboral'"
                    size="sm"
                    :disabled="!isEditable"
                    @click="editDetalleDomicilioLaboral(row.item, row.index, $event.target)"
                  >
                    <font-awesome-icon icon="fa-regular fa-pen-to-square" />
                  </b-button>
                  <b-button
                    v-if="permisosVer.includes(nameModulo)"
                    v-b-tooltip.hover.righttop="'Ver domicilio laboral'"
                    size="sm"
                    @click="viewDetalleDomicilioLaboral(row.item, row.index, $event.target)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-col>
          <div
            v-if="mostrarMensajeDomicilioFaltante"
            class="alert alert-danger"
          >
            Por favor, agregue al menos un domicilio laboral antes de continuar.
          </div>
        </b-row>
      </validation-observer>
    </b-overlay>
    <b-row>
      <b-col md="4">
        <b-button
          id="btnVolverEmpleadores"
          v-b-tooltip.hover
          title="Volver a listado empleadores"
          variant="light"
          squared
          @click="()=>backEmpleadores()"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
      </b-col>
      <b-col
        md="2"
        class="text-right px-0"
      >
        <b-button
          v-if="isWizard"
          id="btnPasoAnterior"
          v-b-tooltip.hover
          title="Anterior"
          variant="outline-primary"
          squared
          style="primary"
          :disabled="isWorking || isFirstStep"
          @click="anteriorWizard()"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
      </b-col>
      <b-col
        md="2"
        class="text-left  px-0"
      >
        <b-button
          v-if="isWizard"
          id="btnPasoPosterior"
          v-b-tooltip.hover
          squared
          variant="outline-primary"
          title="Siguiente"
          type="submit"
          :disabled="isWorking"
          @click.prevent="posteriorWizard()"
        >
          <span />
          <feather-icon
            v-if="!isWorking"
            icon="ArrowRightIcon"
          />
          <span
            v-if="isWorking"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
      <b-col
        v-if="!isWizard"
        md="4"
        class="text-right"
      >
        <b-button
          v-if="permisosUpd.includes(nameModulo)"
          id="btnGuardarDomicilioLaboral"
          v-b-tooltip.hover
          class="text-end"
          variant="primary"
          squared
          title="Guardar domicilio laboral "
          type="submit"
          :disabled="!isEditable || (updateDomicilioLaboralDto.length == 0 && domicilioDtoTemporal.length == 0)"
          @click.prevent="validarFormulario"
        >
          <font-awesome-icon
            v-if="!isWorking"
            icon="fa-solid fa-floppy-disk"
          />
          <span
            v-if="isWorking"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <div class="align-self-end ml-auto">
        <b-col md="12">
          <b-input-group>
            <b-pagination
              id="pgnDomicilioLaboral"
              v-model="currentPage"
              :total-rows="pageTotalElements"
              :per-page="pageSize"
              :disabled="isBusyBuscando"
              @change="changePaginate"
            />
          </b-input-group>
        </b-col>
      </div>
    </b-row>

    <div v-if="showModal">
      <domicilio-laboral
        :domicilio-laboral-modal="modalDataDomicilioLaboral"
        :show-domicilio-laboral-modal="showDomicilioLaboralModal"
        :open-mode="openModeModal"
        :tipo="tipo"
        :domicilio-dto-temporal="domicilioDtoTemporal"
        @contentDomicilioLaboral="contentDomicilioLaboral"
        @newDomicilioLaboral="newDomicilioLaboral"
        @setDomicilioLaboral="setDomicilioLaboral"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { domicilioLaboralResource } from '@/services/domicilioLaboralResource'
import DomicilioLaboral from './DomicilioLaboral.vue'

export default {
  components: {
    DomicilioLaboral,
  },
  props: {
    empleadorDto: {
      type: Object,
      required: false,
      default: null,
    },
    idEmpleador: {
      type: String,
      required: false,
      default: null,
    },
    isWizard: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFirstStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    openMode: {
      type: String,
      required: true,
    },
    editarEnableDisable: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    isLastStep: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      title: 'Nuevo Empleador',
      isWorking: false,
      mostrarMensajeDomicilioFaltante: false,
      showModal: false,
      domicilioLista: ['a', 'b'],
      pageTotalElements: 0,
      currentPage: 1,
      pageSize: 0,
      pageDomicilioLaboral: {
        page: 0,
        size: 7,
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
      showDomicilioLaboralModal: false,
      domicilioDtoTemporal: [],
      modalDataDomicilioLaboral: {},
      updateDomicilioLaboralDto: [],
      domicilioLaboralDto: {
        id: null,
        domicilioCalle: null,
        domicilioNumero: null,
        domicilioOficinaDepto: null,
        codigoPostal: null,
        provincia: null,
        localidad: null,
        delegacion: {
          id: null,
          nombre: null,
        },
        domicilioLaboralEstado: {
          id: null,
          nombre: null,
        },
        domicilioLaboralTipo: {
          id: null,
          tipo: null,
        },
        empleador: {
          id: null,
          nombreFantasia: null,
        },
      },
      optionsPaginationSize: [5, 7, 10, 15, 20, 25, 30],
      nameModulo: 'ABM_EMPLEADORES',
      empleadorId: this.empleadorDto.id,
      isBusyBuscando: false,
      tableDataDomicilioLaboral: [],
      fieldsDomicilioLaboral: [
        {
          key: 'domicilioCalle', label: 'Dirección', thStyle: { width: '18%' },
        },
        {
          key: 'codigoPostal', label: 'CP', thStyle: { width: '12%' },
        },
        {
          key: 'localidad.nombre', label: 'Localidad', thStyle: { width: '18%' },
        },
        {
          key: 'provincia.nombre', label: 'Provincia', thStyle: { width: '18%' },
        },
        {
          key: 'delegacion.nombre', label: 'Delegación', thStyle: { width: '12%' },
        },
        {
          key: 'domicilioLaboralEstado.nombre', label: 'Estado', thStyle: { width: '12%' },
        },
        { key: 'actions', label: 'Acciones', thStyle: { width: '10%' } },
      ],
    }
  },
  computed: {
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.openMode === 'incompleteLoad' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    if (this.idEmpleador !== null) {
      this.empleadorId = this.idEmpleador
      if (this.empleadorId !== undefined || this.empleadorId !== null) {
        this.buscarDomicilioLaboral()
      }
    }
  },
  methods: {
    backEmpleadores() {
      this.$router.push('/empleadores')
    },
    anteriorWizard() {
      this.$emit('previous-step', this.tableDataDomicilioLaboral)
    },
    posteriorWizard() {
      this.validarFormulario()
    },
    validarFormulario() {
      return this.$refs.refDomicilioLaboral.validate().then(success => {
        if (success) {
          const nuevosDomiciliosTemporales = this.domicilioDtoTemporal.filter(dto => dto.id.toString().includes('generated'))
          const domiciliosAActualizarActualizables = this.updateDomicilioLaboralDto.filter(dto => !dto.id.toString().includes('generated'))
          if (nuevosDomiciliosTemporales.length > 0 || domiciliosAActualizarActualizables.length > 0) {
            if (nuevosDomiciliosTemporales.length > 0) {
              this.saveDomicilioLaboral(nuevosDomiciliosTemporales)
            }
            if (domiciliosAActualizarActualizables.length > 0) {
              this.updateDomicilioLaboral(domiciliosAActualizarActualizables)
            }
          } else if (this.openMode !== 'new' && (this.tableDataDomicilioLaboral.length > 0 && this.openMode === 'incompleteLoad')) {
            this.$emit('next-step', null)
          } else {
            this.mostrarMensajeDomicilioFaltante = true
            setTimeout(() => {
              this.mostrarMensajeDomicilioFaltante = false
            }, 3000)
          }
        }
      })
    },
    buscarDomicilioLaboral() {
      this.currentPage = 1
      this.pageDomicilioLaboral.page = 0
      this.getEmpleadorByFilter()
    },
    openAddDomicilioModal() {
      this.openModeModal = 'new'
      this.tipo = 'FISICO'
      this.showDomicilioLaboralModal = true
      this.showModal = true
    },
    handleDtoSubmitted(dto) {
      this.domicilioDtoTemporal.push(dto) // Agrega el dto al arreglo
      this.tableDataDomicilioLaboral.push(dto)
    },
    getEmpleadorByFilter() {
      const filtros = {
        empleadorId: this.empleadorDto.id,
        pageNumber: this.pageDomicilioLaboral.page,
        pageSize: 7,
        sort: 'domicilioLaboralEstado',
        order: 'asc',
      }
      domicilioLaboralResource().postByEmpleadorIdFilter(filtros)
        .then(resultPostByEmpleadorByFilter => {
          this.tableDataDomicilioLaboral = resultPostByEmpleadorByFilter.content
          this.pageTotalElements = resultPostByEmpleadorByFilter.totalElements
          this.pageNumber = resultPostByEmpleadorByFilter.number
          this.pageSize = resultPostByEmpleadorByFilter.size
          if (this.pageDomicilioLaboral.page === 0) {
            this.tableDataDomicilioLaboral = this.tableDataDomicilioLaboral.concat(this.domicilioDtoTemporal)
          }
        }).catch(error => {
          console.error('errors:', error)
          this.isBusyBuscando = false
          this.tableDataDomicilioLaboral = []
        })
        .finally(() => {
          this.isBusyBuscando = false
        })
    },
    contentDomicilioLaboral() {
      this.showDomicilioLaboralModal = false
    },
    addDomicilioLaboral() {
      this.openModeModal = 'new'
      this.tipo = 'FISICO'
      this.modalDataDomicilioLaboral = null
      this.showDomicilioLaboralModal = true
    },
    viewDetalleDomicilioLaboral(data) {
      this.openModeModal = 'view'
      this.tipo = data.domicilioLaboralTipo.tipo
      this.modalDataDomicilioLaboral = data
      this.showDomicilioLaboralModal = true
      this.showModal = true
    },
    editDetalleDomicilioLaboral(data) {
      this.openModeModal = 'edit'
      this.tipo = data.domicilioLaboralTipo.tipo
      this.modalDataDomicilioLaboral = data
      this.showDomicilioLaboralModal = true
      this.showModal = true
    },
    newDomicilioLaboral(domicilioTemporalDto) {
      this.showDomicilioLaboralModal = false
      this.domicilioDtoTemporal.push(domicilioTemporalDto)
      this.tableDataDomicilioLaboral.push(domicilioTemporalDto)
    },
    setDomicilioLaboral() {
      this.showDomicilioLaboralModal = false
      this.updateDomicilioLaboralDto.push(this.modalDataDomicilioLaboral)
      const pos = this.tableDataDomicilioLaboral.findIndex(domLab => domLab.id === this.modalDataDomicilioLaboral.id)
      this.tableDataDomicilioLaboral[pos] = this.modalDataDomicilioLaboral
      this.$refs.refTblDomicilioLaboral.refresh()
    },
    dobleClik(record) {
      this.viewDetalleDomicilioLaboral(record)
    },
    saveDomicilioLaboral(nuevosDomicilios) {
      this.isWorking = true
      this.overlayConfig.message = 'Aguarde ...'
      const updatedDomicilioDtoTemporal = nuevosDomicilios.map(element => {
        const empleadorConId = {
          ...element.empleador,
          id: this.empleadorDto.id,
        }
        // Crear un nuevo objeto element con el nuevo objeto Empleador
        const updatedElement = {
          ...element,
          empleador: empleadorConId,
        }
        if (updatedElement.domicilioLaboralTipo.id === 1) {
          updatedElement.localidad = null
          updatedElement.provincia = null
        }
        return updatedElement
      })
      updatedDomicilioDtoTemporal.forEach(element => {
        const str = element
        if (str.id.toString().includes('generated')) {
          str.id = null
        }
      })
      domicilioLaboralResource().saveAllDomicilioLaboral(updatedDomicilioDtoTemporal)
        .then(resultSaveDomicilioLaboral => {
          if (this.isWizard) {
            // Revisión, esto está mal, se envian dos dtos? se está guardanto en   empleador.domiciliolabora todo de nuevo incluso objeto empleador
            // se debe guardar solamente domicilio empleador. El backend está mal, retorna datos innecesarios.
            this.$emit('next-step', this.domicilioLaboralDto, resultSaveDomicilioLaboral)
          }
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
          this.domicilioDtoTemporal = []
          this.buscarDomicilioLaboral()
        })
    },
    updateDomicilioLaboral(domiciliosAActualizar) {
      domicilioLaboralResource().updateAllDomicilioLaboral(domiciliosAActualizar)
        .then(resultUpdateDomicilioLaboral => {
          // Revisión, esto está mal, se envian dos dtos? se está guardanto en   empleador.domiciliolabora todo de nuevo incluso objeto empleador
          // se debe guardar solamente domicilio empleador. El backend está mal, retorna datos innecesarios.
          if (this.isWizard) {
            this.$emit('next-step', this.domicilioLaboralDto, resultUpdateDomicilioLaboral)
          }
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
          this.updateDomicilioLaboralDto = []
        })
    },
    changePaginate(item) {
      this.pageDomicilioLaboral.page = item - 1
      this.isBusyBuscando = true
      this.getEmpleadorByFilter()
    },
  },
}
</script>
<style>
.truncate-cell {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
