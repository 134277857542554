/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getProvinciasForLocalidadId,
  getAllProvincia,
} from '@/utils/paths'

const pathGetAllProvincia = getAllProvincia
const pathGetProvinciasForLocalidadId = getProvinciasForLocalidadId

export function provinciaResource() {
  function getAllProvincia() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAllProvincia,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getProvinciasForLocalidadId(localidadId) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          pathGetProvinciasForLocalidadId, localidadId,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  return {
    getAllProvincia,
    getProvinciasForLocalidadId,
  }
}
