<template>
  <div>
    <validation-observer ref="refDocumentacion">
      <h4 v-if="openMode == 'new'|| openMode == 'incompleteLoad'">
        {{ title }}
      </h4>
      <b-overlay
        :show="isWorking"
        :variant="overlayConfig.variant"
        :opacity="overlayConfig.opacity"
        :blur="overlayConfig.blur"
      >
        <template #overlay>
          <div v-if="isWorking">
            <div style="text-align: center">
              <span class="spinner-border p-5" />
            </div>
            <div style="text-align: center">
              <h4>{{ overlayConfig.message }}</h4>
            </div>
            <hr>
            <b-progress
              v-if="progressData.progressAdjuntos > 0 && isSavingAdjuntos"
              height="1rem"
              width="450px"
              :max="progressData.progressAdjuntos"
            >
              <b-progress-bar :value="progressData.progressAdjuntosDone">
                <span>Adjuntando {{ progressData.progressAdjuntosDone }} / {{ progressData.progressAdjuntos }}</span>
              </b-progress-bar>
            </b-progress>
          </div>
        </template>
        <b-row
          v-if="documentacionEmpleador.length > 0"
          class="justify-content-md-center"
        >
          <!--class="col-md-12 col-12 my-1 text-center"-->
          <b-card-group
            v-for="(doc, index) in documentacionEmpleador"
            :key="index"
            deck
            class="col-md-4 col-12 text-center"
          >

            <b-card
              :ref="`card-${index}`"
              border-variant="secondary"
            >
              <strong>{{ doc.empleadorDocumentoCategoria.nombre }} ({{ doc.empleadorDocumentoCategoria.obligatorio ? 'Obligatorio': 'Opcional' }})</strong>
              <p v-show="doc.nombre !== null">
                {{ doc.nombre }}
                <small
                  v-if="permisosUpd.includes(nameModulo) && isEditable"
                  class="switch-icon-left"
                >
                  <feather-icon
                    style="cursor:pointer"
                    icon="XSquareIcon"
                    @click="()=>removeFileDoc(doc)"
                  />
                </small>
              </p>
              <p v-show="doc.nombre === null">
                Falta agregar adjunto
              </p>
              <b-button
                v-if="puedeDescargarse(doc)"
                id="btnDownloadEmpleadorDocumento"
                v-b-tooltip.hover
                squared
                size="sm"
                variant="primary"
                :disabled="doc.isDownloading"
                @click="()=>descargarAdjunto(doc)"
              >
                <font-awesome-icon
                  v-if="!doc.isDownloading"
                  v-b-tooltip.hover.right="'Clic para descargar'"
                  style="cursor:pointer"
                  size="lg"
                  icon="fa-solid fa-cloud-arrow-down"
                />
                <span
                  v-if="doc.isDownloading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              </b-button>
              <b-form-file
                v-if="doc.nombre === undefined || doc.nombre === null"
                :ref="`file-uploader-${doc.id}`"
                v-model="doc.file"
                placeholder=""
                :accept="config.extensionesAceptadas"
                browse-text="Examinar"
                @input="check(doc, index)"
              />
              <b-button
                v-if="permisosUpd.includes(nameModulo) && puedeSerBorrado(doc)"
                v-b-tooltip.hover="'Quitar doc'"
                variant="danger"
                squared
                size="sm"
                @click="quitarDoc(doc, index)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-card>
          </b-card-group>
        </b-row>
        <b-row>
          <b-col
            v-if="permisosUpd.includes(nameModulo)"
            md="12"
            class="text-right"
          >
            <b-button
              variant="primary"
              :disabled="!isEditable"
              @click="openModalAddDocumentacion"
            >
              Agregar otra Documentación
            </b-button>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col md="4">
            <b-button
              id="btnVolverEmpleadores"
              v-b-tooltip.hover
              title="Volver a listado empleadores"
              variant="light"
              squared
              @click="()=>backEmpleadores()"
            >
              <feather-icon icon="ArrowLeftIcon" />
            </b-button>
          </b-col>
          <b-col
            md="2"
            class="text-right px-0"
          >
            <b-button
              v-if="permisosUpd.includes(nameModulo) && isWizard"
              id="btnPasoAnterior"
              v-b-tooltip.hover
              title="Anterior"
              variant="outline-primary"
              squared
              style="primary"
              :disabled="isWorking || isFirstStep"
              @click="anteriorWizard()"
            >
              <feather-icon icon="ArrowLeftIcon" />
            </b-button>
          </b-col>
          <b-col
            md="2"
            class="text-left  px-0"
          >
            <b-button
              v-if="!isLastStep && isWizard"
              id="btnPasoPosterior"
              v-b-tooltip.hover
              squared
              variant="outline-primary"
              title="Siguiente"
              type="submit"
              :disabled="isWorking || !isEditable"
              @click.prevent="posteriorWizard()"
            >
              <span />
              <feather-icon
                v-if="!isWorking"
                icon="ArrowRightIcon"
              />
              <span
                v-if="isWorking"
                class="spinner-border spinner-border-sm"
                aria-hidden="true"
              />
            </b-button>
            <b-button
              v-if="isLastStep && isWizard"
              id="btnPasoPosterior"
              v-b-tooltip.hover
              squared
              variant="outline-primary"
              title="Terminar carga"
              type="submit"
              :disabled="isWorking || !isEditable"
              @click.prevent="posteriorWizard()"
            >
              <span>Finalizar</span>

              <span
                v-if="isWorking"
                class="spinner-border spinner-border-sm"
                aria-hidden="true"
              />
            </b-button>
          </b-col>
          <b-col
            v-if="!isWizard"
            md="4"
            class="text-right"
          >
            <b-button
              v-if="permisosUpd.includes(nameModulo)"
              id="btnGuardarDocumentacionEmpleador"
              v-b-tooltip.hover
              class="text-end"
              variant="primary"
              squared
              title="Guardar documentación"
              type="submit"
              :disabled="!isEditable"
              @click.prevent="validarFormulario"
            >
              <font-awesome-icon
                v-if="!isWorking"
                icon="fa-solid fa-floppy-disk"
              />
              <span
                v-if="isWorking"
                class="spinner-border spinner-border-sm"
                aria-hidden="true"
              />
            </b-button>
          </b-col>
          <!--<p
            v-for="(id, index) in documentacionEmpleadorDbIds"
            :key="index"
            deck
          >
            <small>id: {{ id }}</small>
            <br>
          </p>-->

        </b-row>
      </b-overlay>
    </validation-observer>

    <div v-if="showDocumentoCategoriaModal">
      <documentacion-modal
        :show-documento-categoria-modal="showDocumentoCategoriaModal"
        :table-data-documento-categoria="categoriasDisponibles()"
        @agregarDocumentacion="agregarDocumentacion"
        @closeModalDocumentacion="closeModalDocumentacion"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { documentoResource } from '@/services/documentoResource'
import { fileManagement } from '@/utils/fileManagement'
import { documentoCategoriaResource } from '@/services/documentoCategoriaResource'
import DocumentacionModal from './DocumentacionModal.vue'

export default {
  components: {
    DocumentacionModal,
  },
  props: {
    empleadorDto: {
      type: Object,
      required: false,
      default: null,
    },
    idEmpleador: {
      type: String,
      required: false,
      default: null,
    },
    isWizard: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFirstStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    openMode: {
      type: String,
      required: true,
    },
    editarEnableDisable: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    isLastStep: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      title: 'Nuevo Empleador',
      nameModulo: 'ABM_EMPLEADORES',
      isSavingAdjuntos: false,
      isWorking: false,
      showModalUploadFile: false,
      showDocumentoCategoriaModal: false,
      itemAdjunto: null,
      identificadorEmit: null,
      documento: null,
      adjuntosList: [],
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '1',
        blur: '5px',
        message: '',
      },
      config: {
        tamanioMaximoAdjunto: 10, // MB
        extensionesAceptadas: '.pdf, .doc, .txt, .png, .jpg, .jpng',
      },
      documentosTemporales: [],
      documentacionEmpleador: [],
      documentacionEmpleadorDb: [],
      documentacionEmpleadorDbIds: [],
      isBusyBuscando: false,
      tableDataDocumentoCategoria: [],
      empleadorDocumentoCategoriaLista: [],
      docuDto: {
        id: null,
        pathFile: null,
        nombre: null,
        tipo: null,
        extension: null,
        size: null,
        empleadorDocumentoCategoria: {
          id: null,
          nombre: null,
          descripcion: null,
          obligatorio: null,
          habilitado: null,
        },
      },
      progressData: {
        progressAdjuntos: 0,
        progressAdjuntosDone: 0,
      },
      pageTotalElements: 0,
      currentPage: 1,
      pageSize: 0,
      pageDocumento: {
        page: 0,
        size: 7,
      },
    }
  },
  computed: {
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.openMode === 'incompleteLoad' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        user: 'currentUser',
      },
    ),
    buttonNextStepTitle() {
      return this.isLastStep ? 'Finalizar carga empleador' : 'Siguiente'
    },
    buttonNextStepVariant() {
      return this.isLastStep ? 'primary' : 'light'
    },
  },
  created() {
    this.iniciar()
  },
  methods: {
    toast(type, title, html) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">${html}</p>`
      this.$swal({
        icon: type,
        title,
        html: htmlText,
        background: this.swalConfig.background,
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
      })
    },
    check(doc, index) {
      if (doc.file !== null) {
        let extensionArchivo = doc.file.name.split('.').pop()
        if (extensionArchivo !== null) {
          extensionArchivo = extensionArchivo.toLowerCase()
        }
        //  && extensionArchivo === 'pdf'
        if (extensionArchivo !== null && this.config.extensionesAceptadas.includes(extensionArchivo)) {
          if (this.getMBfromBytes(doc.file.size) >= 0 && this.getMBfromBytes(doc.file.size) <= this.config.tamanioMaximoAdjunto) {
            if (doc.file.name.length > 100) {
              this.toast('warning', null, 'El nombre del archivo no debe superar los 100 caracteres')
            } else {
              this.documentacionEmpleador[index].nombre = doc.file.name
              this.documentacionEmpleador[index].id = this.generarRandomId()
              /*
              const categoriaId = this.identificadorEmit ? this.identificadorEmit.id : null
              const adjuntoDto = {
                id: this.generarRandomId(),
                nombreAdjunto: this.file.name,
                adjuntoSize: this.file.size,
                adjunto: this.file,
                tipoAdjunto: this.file.type,
                documentoCategoriaId: categoriaId,
              }
              this.tableDataAdjuntos.push(adjuntoDto) */
              return
            }
          } else if (doc.file.size === 0) {
            this.toast('warning', null, 'El archivo a adjuntar no debe estar vacío')
          } else {
            this.toast('warning', null, `El archivo a adjuntar debe tener un tamaño inferior a ${this.config.tamanioMaximoAdjunto} Mb`)
          }
        } else {
          this.toast('warning', null, `Solo se permite adjuntar archivos en los siguientes formatos ${this.config.extensionesAceptadas}`)
        }
        this.documentacionEmpleador[index].file = null
        const nameFileUploaderRef = `file-uploader-${doc.id}`
        this.$refs[nameFileUploaderRef][0].reset()
      }
    },
    removeFileDoc(docParam) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">¿Desea eliminar el archivo ${docParam.nombre}? </p>`
      this.$swal({
        title: 'Eliminar archivo',
        // text: '¿Desea visualizar el empleador?',
        html: htmlText,
        icon: 'warning',
        background: this.swalConfig.background,
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const docIndex = this.documentacionEmpleador.findIndex(doc => doc.id === docParam.id)
          if (docIndex >= 0) {
            this.documentacionEmpleador[docIndex].nombre = null
            this.documentacionEmpleador[docIndex].id = null
            this.documentacionEmpleador[docIndex].file = null
          }
        }
      })
    },
    puedeDescargarse(doc) {
      let puede
      if (doc.id !== null && doc.id !== undefined) {
        const contieneIdGenerado = doc.id.toString().includes('generated')
        puede = doc.id !== null && contieneIdGenerado === false
      }
      return puede
    },
    puedeSerBorrado(doc) {
      return (!doc.empleadorDocumentoCategoria.obligatorio) && this.isEditable
    },
    categoriasDisponibles() {
      const map = new Map()
      this.documentacionEmpleador.forEach(element => {
        map.set(element.empleadorDocumentoCategoria.id, true)
      })
      const docCatOpcionales = this.empleadorDocumentoCategoriaLista.filter(docCat => docCat.obligatorio === false)
      const arrDocOpcionalesDisponibles = docCatOpcionales.filter(item => !map.get(item.id))
      return arrDocOpcionalesDisponibles
    },
    async iniciar() {
      this.documentacionEmpleador = []
      this.documentacionEmpleadorDb = []
      this.documentacionEmpleadorDbIds = []
      await this.getAllCategorias()
      if (this.openMode !== 'new') {
        await this.findEmpleador(this.idEmpleador)
      }

      this.buildDataDocuments()
    },
    generarRandomId() {
      return `generated${Math.random().toString(36).substring(7)}`
    },
    quitarDoc(docToRemove, index) {
      if (docToRemove.id === null || docToRemove.id === undefined) {
        this.documentacionEmpleador.splice(index, 1)
      } else {
        this.customizeSweetAlert2()
        const htmlText = `<p style="color:${this.swalConfig.textColor}">¿Desea quitar esta documentación ${docToRemove.empleadorDocumentoCategoria.nombre}? </p>`
        this.$swal({
          title: 'Quitar documentación',
          // text: '¿Desea visualizar el empleador?',
          html: htmlText,
          icon: 'warning',
          background: this.swalConfig.background,
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary square',
            cancelButton: 'btn btn-danger square',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.documentacionEmpleador.splice(index, 1)
          }
        })
      }

      /*
      const docIndex = this.documentacionEmpleador.findIndex(doc => doc.id === docToRemove.id)
      if (docIndex !== -1) {
        this.documentacionEmpleador.splice(docIndex, 1)
      } */
    },
    buildDataDocuments() {
      this.documentacionEmpleadorDbIds = this.documentacionEmpleadorDb.map(doc => doc.id)
      const docCatObligatorios = this.empleadorDocumentoCategoriaLista.filter(docCat => docCat.obligatorio === true)
      docCatObligatorios.forEach(docCat => {
        const doc = this.documentacionEmpleadorDb.find(item => item.empleadorDocumentoCategoria.id === docCat.id)
        if (doc !== undefined) {
          this.documentacionEmpleador.push(doc)
        } else {
          const docuDto = {
            // id: this.generarRandomId(),
            id: null,
            pathFile: null,
            nombre: null,
            tipo: null,
            extension: null,
            size: null,
            isDownloading: false,
            empleadorDocumentoCategoria: docCat,
          }
          this.documentacionEmpleador.push(docuDto)
        }
      })
      const docCatOpcionales = this.empleadorDocumentoCategoriaLista.filter(docCat => docCat.obligatorio === false)
      docCatOpcionales.forEach(docCat => {
        const doc = this.documentacionEmpleadorDb.find(item => item.empleadorDocumentoCategoria.id === docCat.id)
        if (doc !== undefined) {
          this.documentacionEmpleador.push(doc)
        }
      })
    },
    async getAllCategorias() {
      await documentoCategoriaResource().findAllByIsActivoCategoriaDocumentacion()
        .then(resultFindAllCategorias => {
          this.empleadorDocumentoCategoriaLista = resultFindAllCategorias
          // this.tableDataDocumentoCategoriaFiltrados = this.tableDataDocumentoCategoria.filter(documento => documento.obligatorio)
        }).catch(error => {
          console.error(error)
          this.EmpleadorDocumentoCategoriaLista = []
        })
        .finally(() => {
          this.isBusyBuscando = false
        })
    },
    backEmpleadores() {
      this.$router.push('/empleadores')
    },
    anteriorWizard() {
      this.$emit('previous-step', this.empleadorDto)
    },
    posteriorWizard() {
      this.validarFormulario()
    },
    async findEmpleador(idEmpleador) {
      this.isWorking = true
      this.overlayConfig.message = 'Recuperando documentación'
      if (idEmpleador !== undefined) {
        await documentoResource().getByEmpleadorIdDocumento(idEmpleador)
          .then(resultsFindByidEmpleador => {
            this.documentacionEmpleadorDb = resultsFindByidEmpleador
          }).catch(error => {
            console.error(error)
          })
          .finally(() => {
            this.isWorking = false
          })
      } else {
        this.isWorking = false
      }
    },
    agregarDocumentacion(empleadorDocumentoCatetoriaId) {
      this.showDocumentoCategoriaModal = false
      this.agregaDoc(empleadorDocumentoCatetoriaId)
    },
    agregaDoc(empleadorDocumentoCatetoriaId) {
      // this.generarRandomId(),
      const tidoDocCat = this.empleadorDocumentoCategoriaLista.filter(docCat => docCat.id === empleadorDocumentoCatetoriaId)
      const docuDto = {
        id: null,
        pathFile: null,
        nombre: null,
        tipo: null,
        extension: null,
        size: null,
        isDownloading: false,
        empleadorDocumentoCategoria: tidoDocCat[0],
      }
      this.documentacionEmpleador.push(docuDto)
    },
    closeModalDocumentacion() {
      this.showDocumentoCategoriaModal = false
    },
    openModalAddDocumentacion() {
      this.showDocumentoCategoriaModal = true
    },
    validarFormulario() {
      return this.$refs.refDocumentacion.validate().then(success => {
        if (success) {
          if (this.validarRequisitos()) {
            this.generarAdjuntos()
          }
        }
      })
    },
    validarRequisitos() {
      const docsMissing = []

      this.documentacionEmpleador.forEach(doc => {
        // if (doc.file === undefined || doc.file === null) {
        if (doc.id === undefined || doc.id === null) {
          docsMissing.push(doc)
        }
      })
      if (docsMissing.length > 0) {
        this.toast('warning', 'Adjuntos', `Archivos pendientes de adjuntar: ${this.buildStringMessage(docsMissing)}`)
        // this.toast('warning', null, docsMissing)
        return false
      }
      return true
    },
    buildStringMessage(detalleErrores) {
      this.customizeSweetAlert2()
      let message = '<ul>'
      detalleErrores.forEach(item => {
        message += `<li style="color: ${this.swalConfig.textColor}"> ${item.empleadorDocumentoCategoria.nombre} <br>`
        /* message += 'Adjuntos: <br>'
        item.adjuntosNombre.forEach(adjunto => {
          message += ` ${adjunto.nombreAdjunto} <br>`
        }) */
        message += '</li>'
      })
      message += '</ul>'
      // message += `<br> <p style="color: ${this.swalConfig.textColor}">Intente nuevamente solicitar y adjuntar para los beneficiarios indicados.</p>`
      return message
    },
    async generarAdjuntos() {
      this.overlayConfig.message = 'Documentación'
      this.isSavingAdjuntos = true
      this.progressData.progressAdjuntos = this.documentacionEmpleador.length
      this.isWorking = true
      const docsToSave = []
      const docsToBeRemoved = []
      this.documentacionEmpleadorDbIds.forEach(id => {
        const index = this.documentacionEmpleador.findIndex(item => item.id === id)
        if (index === -1) {
          docsToBeRemoved.push(id)
        }
      })
      if (docsToBeRemoved.length > 0) {
        await this.deleteDocs(docsToBeRemoved)
      }
      this.documentacionEmpleador.forEach(doc => {
        const contieneIdGenerado = doc.id.toString().includes('generated')
        if (contieneIdGenerado) {
          const dto = {
            documentoCategoriaId: doc.empleadorDocumentoCategoria.id,
            adjunto: doc.file,
          }
          docsToSave.push(dto)
        }
      })
      await this.guardarMultiplesAdjuntos(docsToSave).then(() => {
      })
      this.overlayConfig.message = ''
      this.progressData.progressAdjuntosDone = 0
      this.progressData.progressAdjuntos = 0
      this.isWorking = false
      this.isSavingAdjuntos = false
      if (this.isWizard) {
        this.$emit('next-step', this.documentacionEmpleador)
      } else if (docsToBeRemoved.length > 0 || docsToSave.length > 0) {
        // Entonces lago se hizo, insert o delete, por eso llamamos a this.iniciar() para recargar.
        this.iniciar()
      }
    },
    guardarAdjunto(id, categoriaId, adjunto) {
      return documentoResource().uploadFileAttachment(id, categoriaId, adjunto)
    },
    async deleteDocs(docsToBeRemoved) {
      const promesas = []
      await docsToBeRemoved.forEach(async idToRemove => {
        const promesa = documentoResource().deleteDocumento(idToRemove).then(() => {
        }).catch(error => {
          console.error('error al eliminar documento empleador:', error)
        })
        promesas.push(promesa)
      })
      return Promise.allSettled(promesas)
    },
    guardarMultiplesAdjuntos(adjuntos) {
      const promesas = []
      let count = 1
      adjuntos.forEach(async adjunto => {
        const promesa = this.guardarAdjunto(this.empleadorDto.id, adjunto.documentoCategoriaId, adjunto.adjunto).then(() => {
          this.progressData.progressAdjuntosDone = count
          count += 1
        }).catch(error => {
          console.error('Error al enviar adjunto:', error)
        })
        promesas.push(promesa)
      })
      // eslint-disable-next-line consistent-return
      return Promise.allSettled(promesas)
    },
    descargarAdjunto(docToDownload) {
      const docIndex = this.documentacionEmpleador.findIndex(doc => doc.id === docToDownload.id)
      this.documentacionEmpleador[docIndex].isDownloading = true
      documentoResource().getAdjuntoByte(docToDownload.id).then(resBytes => {
        fileManagement().getAdjunto(resBytes, docToDownload.nombre, docToDownload.tipoAdjunto)
        // const blob = new Blob([resBytes], { type: 'application/x-www-form-urlencoded' })
        // saveAs(blob, row.item.nombreAdjunto)
        this.documentacionEmpleador[docIndex].isDownloading = false
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.documentacionEmpleador[docIndex].isDownloading = false
        })
    },
    eliminarAdjunto(adjuntoEliminado) {
      // Encuentra y elimina el adjunto en documentosTemporales
      this.documentosTemporales.forEach((documentos, documentosIndex) => {
        const adjuntoIndex = documentos.findIndex(adjunto => adjunto.id === adjuntoEliminado.id)
        if (adjuntoIndex !== -1) {
          documentos.splice(adjuntoIndex, 1)
          if (documentos.length === 0) {
            // Si no quedan adjuntos en este elemento, también elimina el elemento
            this.documentosTemporales.splice(documentosIndex, 1)
          }
        }
      })
    },
    dobleClik(record) {
      this.viewDetalleDocumento(record)
    },
    buscarDocumentacion() {
      this.currentPage = 1
      this.pageDocumento.page = 0
      this.getDocumentacionByFilter()
    },
    getDocumentacionByFilter() {
      const filtros = {
        empleadorId: this.empleadorDto.id,
        pageNumber: this.pageDocumento.page,
        pageSize: this.pageDocumento.size,
        sort: 'nombre',
        order: 'asc',
      }
      documentoResource().postByEmpleadorIdFilter(filtros)
        .then(resultPostByEmpleadorByFilter => {
          this.tableDataDocumento = resultPostByEmpleadorByFilter.content
          this.pageTotalElements = resultPostByEmpleadorByFilter.totalElements
          this.pageNumber = resultPostByEmpleadorByFilter.number
          this.pageSize = resultPostByEmpleadorByFilter.size
        }).catch(error => {
          console.error('errors:', error)
          this.tableDataDocumento = []
        })
        .finally(() => {
          this.isBusyBuscando = false
        })
    },
  },
}
</script>
<style>
</style>
