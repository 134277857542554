<template>
  <div>
    <b-modal
      id="modalDomicilioType"
      v-model="showDomicilioLaboralModal"
      :title="getMensaje"
      no-close-on-backdrop
      @close="closeModal"
    >
      <b-form-group>
        <b-form-radio-group
          v-model="selectedDomicilioType"
          :disabled="!isEditable"
          @input="updateSelectedDomicilioType"
        >
          <b-form-radio value="FISICO">
            Domicilio Físico
          </b-form-radio>
          <b-form-radio value="VIRTUAL">
            Domicilio Virtual
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <domicilio-laboral-fisico
        ref="domicilioLaboralFisico"
        :show-domicilio-laboral-modal="showDomicilioLaboralModal"
        :open-mode="openMode"
        :selected-domicilio-type="selectedDomicilioType"
        :domicilio-laboral-modal="domicilioLaboralModal"
        @handle-dto-submitted="handleDtoSubmitted"
        @update-dto-submitted="updateDtoSubmitted"
        @cambiar-tipo-domicilio="cambiarTipoDomicilio"
      />
      <template #modal-footer>
        <div
          v-if="openMode != 'view'"
          class="d-flex justify-content-between"
        >
          <b-button
            size="sm"
            squared
            variant="danger"
            @click="closeModal"
          >Cancelar</b-button>
          <b-button
            size="sm"
            squared
            variant="primary"
            @click="okModal"
          >{{ tituloBotonAgregar }} </b-button>
        </div>
        <div
          v-if="openMode == 'view'"
          class="d-flex justify-content-between"
        >
          <b-button
            size="sm"
            squared
            variant="primary"
            @click="closeModal"
          >Cerrar</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DomicilioLaboralFisico from './DomicilioLaboralFisico.vue'

export default {
  name: 'DomicilioLaboral',
  components: {
    DomicilioLaboralFisico,
  },
  props: {
    showDomicilioLaboralModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    domicilioLaboralModal: {
      type: Object,
      required: true,
    },
    editarEnableDisable: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    openMode: {
      type: String,
      required: true,
    },
    tipo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedDomicilioType: '',
    }
  },
  computed: {
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.editarEnableDisable)
    },
    tituloBotonAgregar() {
      return this.openMode === 'new' ? 'Agregar' : 'Modificar'
    },
    getMensaje() {
      if (this.openMode === 'new') {
        return 'Nuevo domiclio laboral'
      } if (this.openMode === 'edit') {
        return 'Edición de domicilio laboral'
      } if (this.openMode === 'view') {
        return 'Domicilio laboral'
      }
      return 'Domicilio laboral'
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  watch: {
    tipo(newTipo) {
      if (newTipo) {
        this.selectedDomicilioType = newTipo
      }
    },
    selectedDomicilioType(newType) {
      this.$emit('update:selectedDomicilioType', newType)
    },
  },
  created() {
    if (this.domicilioLaboralModal.id !== null) {
      this.selectedDomicilioType = this.tipo
    }
  },
  methods: {
    cambiarTipoDomicilio() {
      this.selectedDomicilioType = this.tipo
    },
    updateSelectedDomicilioType(value) {
      this.selectedDomicilioType = value
    },
    okModal() {
      this.$refs.domicilioLaboralFisico.validationDomicilioLaboralModal()
    },
    closeModal(bvModalEvt) {
      this.$emit('contentDomicilioLaboral', false)
      bvModalEvt.preventDefault()
    },
    handleDtoSubmitted(dto) {
      this.$emit('newDomicilioLaboral', dto)
    },
    updateDtoSubmitted(dto) {
      this.$emit('setDomicilioLaboral', dto)
    },
  },
}
</script>
