var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"refDomicilioLaboralFisico"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[(!_vm.virtual)?_c('b-form-group',{attrs:{"id":"frmCalle","label":"Calle"}},[_c('validation-provider',{attrs:{"name":"calle","rules":"required|max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-input',{attrs:{"id":"txtCalle","state":errors.length > 0 ? false:null,"autocomplete":"off","maxlength":"250","disabled":!_vm.isEditable},model:{value:(_vm.domicilioLaboralDto.domicilioCalle),callback:function ($$v) {_vm.$set(_vm.domicilioLaboralDto, "domicilioCalle", $$v)},expression:"domicilioLaboralDto.domicilioCalle"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Calle obligatoria.")]):_vm._e(),(failedRules.max)?_c('small',{staticClass:"text-danger"},[_vm._v("Máximo 250 caracteres.")]):_vm._e()],1)}}],null,false,876761522)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[(!_vm.virtual)?_c('b-form-group',{attrs:{"id":"frmNumero","label":"Número"}},[_c('validation-provider',{attrs:{"name":"numero","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-input',{attrs:{"id":"txtDomicilioNumero","state":errors.length > 0 ? false:null,"autocomplete":"off","type":"number","maxlength":"50","oninput":"if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","disabled":!_vm.isEditable},on:{"input":_vm.restrictToNumbers},model:{value:(_vm.domicilioLaboralDto.domicilioNumero),callback:function ($$v) {_vm.$set(_vm.domicilioLaboralDto, "domicilioNumero", $$v)},expression:"domicilioLaboralDto.domicilioNumero"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Número obligatorio.")]):_vm._e(),(failedRules.max)?_c('small',{staticClass:"text-danger"},[_vm._v("Máximo 50 caracteres.")]):_vm._e()],1)}}],null,false,4271659639)})],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[(!_vm.virtual)?_c('b-form-group',{attrs:{"id":"frmOficinaDepto","label":"Oficina/Depto"}},[_c('validation-provider',{attrs:{"name":"oficinaDepto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('b-form-input',{attrs:{"id":"txtOficinaDepto","state":errors.length > 0 ? false:null,"maxlength":"250","autocomplete":"off","disabled":!_vm.isEditable},model:{value:(_vm.domicilioLaboralDto.domicilioOficinaDepto),callback:function ($$v) {_vm.$set(_vm.domicilioLaboralDto, "domicilioOficinaDepto", $$v)},expression:"domicilioLaboralDto.domicilioOficinaDepto"}})],1)}}],null,false,2060728538)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[(!_vm.virtual)?_c('b-form-group',{attrs:{"id":"frmCodigoPostal","label":"Código Postal"}},[_c('validation-provider',{attrs:{"name":"codigoPostal","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-input',{attrs:{"id":"txtCodigoPostal","state":errors.length > 0 ? false:null,"autocomplete":"off","type":"number","disabled":_vm.isBusyBuscandoLocalidad || _vm.isBusyBuscandoProvincias || !_vm.isEditable},on:{"input":_vm.restrictToNumbers},model:{value:(_vm.domicilioLaboralDto.codigoPostal),callback:function ($$v) {_vm.$set(_vm.domicilioLaboralDto, "codigoPostal", $$v)},expression:"domicilioLaboralDto.codigoPostal"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Código Postal obligatorio.")]):_vm._e(),(failedRules.max)?_c('small',{staticClass:"text-danger"},[_vm._v("Máximo 50 caracteres.")]):_vm._e()],1)}}],null,false,733651529)})],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[(!_vm.virtual)?_c('b-form-group',{attrs:{"id":"frmLocalidad","label":"Localidad"}},[_c('validation-provider',{attrs:{"name":"localidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[(_vm.isBusyBuscandoLocalidad)?_c('small',[(_vm.isBusyBuscandoLocalidad)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"aria-hidden":"true"}}):_vm._e(),_vm._v(" Buscando localidades ")]):_vm._e(),(!_vm.isBusyBuscandoLocalidad)?_c('b-form-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Seleccione la localidad'),expression:"'Seleccione la localidad'",modifiers:{"hover":true}}],attrs:{"id":"selectLocalidad","state":errors.length > 0 ? false:null,"options":_vm.optionsLocalidad,"value-field":"id","text-field":"nombre","aria-busy":_vm.isBusyBuscandoLocalidad ? 'true' : 'false',"disabled":_vm.isBusyBuscandoProvincias || !_vm.isEditable,"rounded":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [(!_vm.isBusyBuscandoLocalidad)?_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" Seleccione ")]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.domicilioLaboralDto.localidad.id),callback:function ($$v) {_vm.$set(_vm.domicilioLaboralDto.localidad, "id", $$v)},expression:"domicilioLaboralDto.localidad.id"}}):_vm._e(),(failedRules.required && !_vm.isBusyBuscandoLocalidad)?_c('small',{staticClass:"text-danger"},[_vm._v(" Localidad obligatoria. ")]):_vm._e()],1)}}],null,false,354546418)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[(!_vm.virtual)?_c('b-form-group',{attrs:{"id":"frmProvincia","label":"Provincia"}},[_c('validation-provider',{attrs:{"name":"provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[(!_vm.isBusyBuscandoProvincias)?_c('b-form-input',{attrs:{"id":"txtProvincia","state":errors.length > 0 ? false : null,"plaintext":""},model:{value:(_vm.domicilioLaboralDto.provincia.nombre),callback:function ($$v) {_vm.$set(_vm.domicilioLaboralDto.provincia, "nombre", $$v)},expression:"domicilioLaboralDto.provincia.nombre"}}):_vm._e(),(_vm.isBusyBuscandoProvincias)?_c('small',[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"aria-hidden":"true"}}),_vm._v(" Buscando provincia ")]):_vm._e(),(failedRules.required && !_vm.isBusyBuscandoProvincias)?_c('small',{staticClass:"text-danger"},[_vm._v(" Provincia obligatoria. ")]):_vm._e()],1)}}],null,false,787644605)})],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"frmDelegacion","label":"Delegación"}},[_c('validation-provider',{attrs:{"name":"delegacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Seleccione la delegación'),expression:"'Seleccione la delegación'",modifiers:{"hover":true}}],attrs:{"id":"selectDelegacion","options":_vm.optionsDelegacion,"state":errors.length > 0 ? false:null,"value-field":"id","text-field":"nombre","disabled":_vm.isBusyBuscando || !_vm.isEditable,"rounded":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" Seleccione ")])]},proxy:true}],null,true),model:{value:(_vm.domicilioLaboralDto.delegacion.id),callback:function ($$v) {_vm.$set(_vm.domicilioLaboralDto.delegacion, "id", $$v)},expression:"domicilioLaboralDto.delegacion.id"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v(" Delegación obligatoria. ")]):_vm._e()],1)}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"frmEmpleadorEstado","label":"Estado"}},[_c('validation-provider',{attrs:{"name":"empleadorEstado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Seleccione el estado'),expression:"'Seleccione el estado'",modifiers:{"hover":true}}],attrs:{"id":"selectEmpleadorEstadi","state":errors.length > 0 ? false:null,"options":_vm.optionsEstado,"value-field":"id","text-field":"nombre","aria-busy":_vm.isBusyBuscandoEstados ? 'true' : 'false',"disabled":_vm.openMode !== 'edit',"rounded":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" Seleccione ")])]},proxy:true}],null,true),model:{value:(_vm.domicilioLaboralDto.domicilioLaboralEstado.id),callback:function ($$v) {_vm.$set(_vm.domicilioLaboralDto.domicilioLaboralEstado, "id", $$v)},expression:"domicilioLaboralDto.domicilioLaboralEstado.id"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v(" Estado obligatorio. ")]):_vm._e()],1)}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }